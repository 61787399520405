exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-csgostats-index-js": () => import("./../../../src/pages/projects/csgostats/index.js" /* webpackChunkName: "component---src-pages-projects-csgostats-index-js" */),
  "component---src-pages-projects-currency-index-js": () => import("./../../../src/pages/projects/currency/index.js" /* webpackChunkName: "component---src-pages-projects-currency-index-js" */),
  "component---src-pages-projects-edward-hotel-index-js": () => import("./../../../src/pages/projects/edward-hotel/index.js" /* webpackChunkName: "component---src-pages-projects-edward-hotel-index-js" */),
  "component---src-pages-projects-esea-client-index-js": () => import("./../../../src/pages/projects/esea-client/index.js" /* webpackChunkName: "component---src-pages-projects-esea-client-index-js" */),
  "component---src-pages-projects-sure-test-index-js": () => import("./../../../src/pages/projects/sure-test/index.js" /* webpackChunkName: "component---src-pages-projects-sure-test-index-js" */)
}

